<template>
	<div class="downloads">
		<div class="main">
			<div class="main-image">
				<img class="image" src="../assets/img/landingPage/download-image2.png" alt="" />
				<div class="text">
					<img class="text-image" src="../assets/img/landingPage/download-text2.png" alt="" />
					<div class="container-image">
						<div class="container-qrcode">
							<img style="z-index: 99;" src="../assets/img/landingPage/logo.png" alt="" />
							<div id="qrcode-image" ref="qrcode-image" class="qrcode" />
						</div>
						<div class="download-buttons">
							<a download :href="androidUrl">
								<img class="icon" src="../assets/img/landingPage/download-icon-android1.png" alt="" />
							</a>
							<a :href="iosUrl">
								<img class="icon" src="../assets/img/landingPage/download-icon-ios1.png" alt="" />
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import QRCode from "qrcodejs2";
export default {
	data() {
		return {
			// isDev: location.hostname == 'dev-web.01tiyu.com'
		};
	},
	computed: mapState(['iosUrl', 'androidUrl', 'downloadPageUrl']),
	async mounted() {
		await this.$store.dispatch('getDownloadUrls');
		this.makeQrcode();
	},
	methods: {
		makeQrcode() {
			new QRCode("qrcode-image", {
				width: 230, //宽度
				height: 230, // 高度
				text: this.downloadPageUrl || location.href
			});
		}
	}
};
</script>

<style lang="less">
.downloads {
	padding: 0;
	height: 100%;

	.main {
		height: 100%;
		background: url('../assets/img/landingPage/download-bg1.png') no-repeat center center;
		background-size: 100% 100%;
		box-sizing: border-box;
		padding: 0.3vw 0 0 0.8vw;

		&-image {
			display: flex;
			height: 100%;

			.image {
				display: block;
				width: 51.4vw;
			}

			.text {
				// padding: 5.5vw 0 0 0;
				box-sizing: border-box;
				display: flex;
				flex-direction: column;
				font-size: 1.5vw;
				font-weight: bold;
				color: #ffffff;

				&-image {
					display: block;
					width: 36.67vw;
				}

				.container-image {
					display: flex;
					margin-top: 1.6vw;

					.container-qrcode {
						position: relative;
						width: 15.1vw;
						height: 15.1vw;
						// border: 1px solid #FFFFFF;
						// border-radius: 2vw;
						margin-left: 4vw;

						img {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
						}

						.qrcode {
							display: flex;
							width: 100%;
							height: 100%;
							justify-content: center;
							align-items: center;
							img {
								padding: 10px;
								box-sizing: border-box;
								border-radius: 5px;
								background-color: #FFF;
							}
						}
					}

					.download-buttons {
						display: flex;
						margin-left: 3.8vw;
						flex-direction: column;
						justify-content: space-around;
					}
				}
			}
		}
	}
}
</style>
